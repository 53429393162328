import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import Layout from "../components/layout"
import Hero, { HeroTitleDescription } from "../components/Hero"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockContentPrimary from "../components/block content/blockContentPrimary"

const ProgramsPage = ({ data }) => {
  const heroFluid = convertToBgImage(getImage(data.heroImage)).fluid
  const defaultProgramImage =
    data.defaultProgramImage.childImageSharp.gatsbyImageData
  const {
    programs: { edges: programData },
  } = data

  // Flatten/simplify array
  const programs = programData.flatMap(program => [program.node])

  // For each event, extract info and put it in correct structure:

  return (
    <Layout>
      <SEO title="Programs" />
      <Hero props={{ heroFluid, className: "bg-opacity-50" }}>
        <HeroTitleDescription
          title="Programs"
          text="At W.I.N.K.S. we offer a wide range of programming that encourages the health and well-being of our students. Some of the activities we offer are as follows:"
        />
      </Hero>
      <PageContentContainer>
        {programs.map((program, index) => {
          const oddItem = index % 2 === 0
          return (
            <BannerContainer
              color={oddItem ? "bg-primary" : "bg-secondary"}
              className="grid justify-items-center py-20"
            >
              <div className="px-5 grid  justify-items-center lg:justify-items-start w-full lg:grid-flow-col gap-x-10 max-w-7xl mx-5 lg:grid-cols-programsLg">
                <GatsbyImage
                  image={
                    program?.featureImage?.asset?.gatsbyImageData ||
                    defaultProgramImage
                  }
                  style={{ width: 250, height: 250 }}
                  className="shadow-extra rounded-full overflow-hidden bg-primary"
                  alt=""
                />
                <div
                  className={` max-w-5xl ${
                    oddItem ? "text-white" : "text-primary"
                  }  flex-grow programs`}
                >
                  <h2
                    className={`${
                      oddItem ? "text-secondary" : "text-primary"
                    } my-5 text-center lg:text-left text-4xl font-bold`}
                  >
                    {program.title}
                  </h2>
                  <BlockContentPrimary blockData={program._rawPostContent} />
                </div>
              </div>
            </BannerContainer>
          )
        })}
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query ProgramsPageQuery {
    heroImage: file(relativePath: { eq: "Programs.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    defaultProgramImage: file(
      relativePath: { eq: "denis-kirichenko-CAOMmLlQAOs-unsplash.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 250, placeholder: BLURRED)
      }
    }
    programs: allSanityProgram(sort: { fields: weight }) {
      edges {
        node {
          id
          title
          _rawPostContent(resolveReferences: { maxDepth: 10 })
          featureImage {
            asset {
              gatsbyImageData(width: 250, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`

export default ProgramsPage
